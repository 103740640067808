
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { References } from '@/wms/domain/catalogs/references/References';
import { Store } from '@/wms/domain/layout/store/Store';
import { Stock } from '@/wms/domain/stock/Stock';
import Vue from 'vue';
import Component from 'vue-class-component';
import { StockDocumentsLin } from '@/wms/domain/stock/StockDocumentsLin';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import EntitySearch from '@/core/components/shared/EntitySearch.vue';
import BulkStock from './BulkStock.vue';
import { UpdateBulkPickingStatus } from '@/wms/application/bulk/update/UpdateBulkPickingStatus';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';
import { FindAllCommodityTypes } from '@/freight/application/commodity_type/search/FindAllCommodityType';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { FindBulksForPickingByFilters } from '@/wms/application/bulk/search/FindBulksForPickingByFilters';
import { GroupedBulk } from '@/wms/domain/bulk/GroupedBulk';
import PickingListFormat from './PickingListFormat.vue';
import { FindNoShippedPickingListsNumber } from '@/freight/application/pickingList/search/FindNoShippedPickingListsNumber';
import { FindPickingListDataForReport } from '@/freight/application/pickingList/search/FindPickingListDataForReport';
import { PickingListForReport } from '@/freight/domain/pickingList/PickingListForReport';
import html2pdf from 'html2pdf.js';
import ConsigneeSearch from '@/core/components/shared/ConsigneeSearch.vue';
import { FindHouseSerialsByDateRange } from '@/courier/application/uses_cases/House/search/FindHouseSerialsByDateRange';
import { FindMasterBlByHouses } from '@/wms/application/billOfLanding/search/FindMasterBlByHouses';

interface Filters {
  dateFrom: string;
  dateTo: string;
  commodity: CommodityType | null;
  customer: Entity | null;
  masterBl: string | null;
  houseBl: string[];
  consignee: string | null;
}

@Component({
  name: 'PickingAndPackingCenter',
  components: {
    EntitySearch,
    BulkStock,
    PickingListFormat,
    ConsigneeSearch
  }
})
export default class PickingAndPackingCenter extends Vue {
  @Inject(TYPES.BULK_UPDATE_PICKING_STATUS)
  private updateBulkPickingStatus!: UpdateBulkPickingStatus;
  @Inject(TYPES.COMMODITY_TYPE_FIND_ALL)
  private commodityTypeFindAll!: FindAllCommodityTypes;
  @Inject(TYPES.BULK_FIND_FOR_PICKING)
  private findBulksForPicking!: FindBulksForPickingByFilters;
  @Inject(TYPES.PICKING_LIST_FIND_NO_SHIPPED)
  readonly findPickingList!: FindNoShippedPickingListsNumber;
  @Inject(TYPES.PICKING_LIST_FIND_DATA_FOR_REPORT)
  readonly findPickingListData!: FindPickingListDataForReport;
  @Inject(TYPES.HOUSE_FIND_SERIALS_BY_DATE_RANGE)
  private findHouseSerials!: FindHouseSerialsByDateRange;
  @Inject(TYPES.MASTER_BILL_OF_LANDING_FIND_BY_HOUSES)
  private findMasterBLList!: FindMasterBlByHouses;

  mounted() {
    this.getLists();
  }

  //Variables
  isLoading = false;
  loadingMasterList = false;
  selectedHouseBL = '';
  selectedMasterBL: BillOfLanding | null = null;
  selectedPickingList: PickingListForReport | null = null;
  pickingInfo: PickingListForReport | null = null;
  carrier: Entity | null = null;

  //Data
  stockMovements: StockDocumentsLin[] = [];
  stockResume: Stock[] = [];
  bulksInStock: GroupedBulk[] = [];
  masterBlList: string[] = [];
  houseBlList: string[] = [];
  commodityList: CommodityType[] = [];
  pickingListList: number[] = [12, 13, 14];

  //Filters
  filters: Filters = {
    dateFrom: new Date().toISOString().substr(0, 10),
    dateTo: new Date().toISOString().substr(0, 10),
    commodity: null,
    customer: null,
    masterBl: null,
    houseBl: [],
    consignee: null
  };
  //Lists
  stores: Store[] = [];
  entities: Entity[] = [];
  references: References[] = [];
  pickedList: string[] = [];

  get downloadablePDFName() {
    return `${this.$t('general.picking list')}-${this.pickingInfo?.number}`;
  }

  //Methods

  getLists() {
    this.findCommodity();
    this.findPickingListInfo();
  }

  async findBulksInStockHandler() {
    try {
      this.isLoading = true;
      const res = await this.findBulksForPicking.internalExecute(this.filters);

      this.bulksInStock = res;
      this.isLoading = false;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  pick(serials: string[], pick = true) {
    if (pick) {
      this.addToPickedList(serials);
    } else {
      this.removeFromPickedList(serials);
    }
  }

  addToPickedList(serials: string[]) {
    this.pickedList.push(...serials);
  }

  removeFromPickedList(serials: string[]) {
    this.pickedList = this.pickedList.filter(p => !serials.includes(p));
  }

  async executePick() {
    try {
      this.isLoading = true;

      if (!this.carrier) {
        // TODO: Add translation
        this.$swal.fire({
          icon: 'error',
          title: 'Carrier is required',
          text: 'Please select a carrier to continue'
        });
        return false;
      }

      const res = await this.updateBulkPickingStatus.internalExecute({
        bulks: this.pickedList,
        status: true,
        carrier: this.carrier.code
      });

      if (!('error' in res)) {
        return true;
      }

      return false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  selectAll() {
    // this.bulksInStock.forEach(b => {
    //   b.forPicking = true;
    //   this.addToPickedList(b);
    // });
  }

  unSelect() {
    // this.bulksInStock.forEach(b => {
    //   b.forPicking = false;
    //   this.removeFromPickedList(b);
    // });
  }

  async findCommodity() {
    try {
      this.isLoading = true;
      const res = await this.commodityTypeFindAll.execute();
      this.commodityList = res;
      this.isLoading = false;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async findMasterBL() {
    try {
      if (!this.filters.houseBl.length) return;
      this.loadingMasterList = true;
      const res = await this.findMasterBLList.internalExecute(this.filters.houseBl);
      this.masterBlList = res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.loadingMasterList = false;
    }
  }

  async findHouses() {
    try {
      this.isLoading = true;
      const res = await this.findHouseSerials.internalExecute({
        from: this.filters.dateFrom,
        to: this.filters.dateTo
      });
      this.houseBlList = res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  async findPickingListInfo() {
    try {
      this.isLoading = true;
      const res = await this.findPickingList.internalExecute();
      this.pickingListList = res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async findPickingListDataForReport(pickingList: number | null) {
    if (!pickingList) return;

    try {
      this.isLoading = true;
      const res = await this.findPickingListData.internalExecute(pickingList);
      this.pickingInfo = res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async printFunction() {
    this.isLoading = true;
    const element = document.getElementById('picking-format');
    const opt = {
      margin: 0.5,
      filename: this.downloadablePDFName,
      image: { type: 'jpeg', quality: 0.2 },
      html2canvas: { scale: 2, letterRendering: true, useCORS: true, logging: true },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'p' }
    };
    await html2pdf()
      .set(opt)
      .from(element)
      .toPdf()
      .save();
    this.isLoading = false;
  }
}
