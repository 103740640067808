
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { PickingListForReport } from '@/freight/domain/pickingList/PickingListForReport';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'PickingListFormat'
})
export default class PickingListFormat extends Vue {
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly findByIdCompany!: CompanyFindById;

  @Prop({ type: Object, required: true }) pickingList!: PickingListForReport;

  mounted() {
    this.getCompanyInfo();
  }
  isLoading = false;

  companyName = '';
  companyAdress = '';
  companyPhoneNumber = '';
  companyEmail = '';
  companyZipCode = '';
  logoImg = localStorage.getItem('urlLogoCompany') as string;

  /**
   * @description Funcion para obtener la informacion de la empresa
   * @returns void
   * @memberof PreRegisterForm
   * @throws {Error}
   */
  async getCompanyInfo() {
    try {
      this.isLoading = true;
      const companyId = localStorage.getItem('businessId') as string;
      const res: any = await this.findByIdCompany.execute(companyId);
      this.isLoading = false;
      this.companyName = res.businessName;
      this.companyAdress = res.address;
      this.companyPhoneNumber = res.phoneNumber;
      this.companyEmail = res.email;
      this.companyZipCode = res.zipCode;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  get fields() {
    return [
      {
        label: this.$t('general.quantity'),
        key: 'quantity'
      },
      {
        label: this.$t('general.SKU'),
        key: 'reference'
      },
      {
        label: this.$t('general.description'),
        key: 'description'
      },
      {
        label: this.$t('general.weight'),
        key: 'weight'
      },
      // {
      //   label: 'L x W x H',
      //   key: 'dimentions',
      //   formatter: (value: string, key: string, item: any) => {
      //     return `${item.length} x ${item.width} x ${item.height}`;
      //   }
      // },
      {
        label: this.$t('general.volume'),
        key: 'volume'
      }
    ];
  }
}
